<template>
  <div>
    <canvas ref="idCardCanvas" :width="cardWidth" :height="cardHeight" style="display:none"></canvas>
    <img v-if="generatedCardUrl" :src="generatedCardUrl" class="w-full mt-4" alt="Generated Bank Card" />
  </div>
</template>

<script>
import backgroundImage from '@/assets/img/bank-logo.png'
import {isNullOrEmpty} from "@/utils"; // 假设背景图片存放在这个路径
import moment from 'moment'

export default {
  props: {
    cardData: {
      type: Object,
      required: true
    },
    cardWidth: {
      type: Number,
      default: 850
    },
    cardHeight: {
      type: Number,
      default: 540
    }
  },
  data() {
    return {
      generatedCardUrl: null,
      backgroundImage: backgroundImage
    }
  },
  computed: {
    expiryDate() {
      if (!this.cardData?.created_at) return ''
      return moment(this.cardData.created_at)
        .add(3, 'years')
        .format('YYYY/MM/DD')
    }
  },
  methods: {
    async generateIdCard() {
      const canvas = this.$refs.idCardCanvas;
      const ctx = canvas.getContext('2d');

      // 加载背景图片
      const bgImg = await this.loadImage(this.backgroundImage);
      ctx.drawImage(bgImg, 0, 0, this.cardWidth, this.cardHeight);

      // 设置卡号样式
      ctx.font = 'bold 42px Arial';
      ctx.fillStyle = '#FFFFFF';

      // 格式化卡号 (每4位加空格)
      const formattedCardNo = this.cardData.bank_no.replace(/(\d{4})/g, '$1 ').trim();
      ctx.fillText(formattedCardNo, 60, 370);

      // 设置姓名样式
      ctx.font = '24px Arial';
      ctx.fillText(this.cardData.get_name, 60, 420);

      // 设置有效期样式
      ctx.font = '20px Arial';
      ctx.fillText(this.expiryDate, 60, 460);

      // 生成最终图片URL
      this.generatedCardUrl = canvas.toDataURL('image/png');
      this.$emit('card-generated', this.generatedCardUrl);
    },
    loadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
        img.src = url;
      });
    }
  },
  mounted() {
    this.generateIdCard();
  },
  watch: {
    cardData: {
      handler() {
        this.generateIdCard();
      },
      deep: true
    }
  }
}
</script>
