<template>
  <van-popup
    v-model="show"
    position="bottom"
    round
    class="payment-dialog"
  >
    <div class="p-4">
      <div class="flex justify-between items-center mb-4">
        <span class="text-lg font-medium">选择支付方式</span>
        <van-icon name="cross" @click="onClose" />
      </div>

      <div class="amount-info bg-[#F8F8F8] p-3 rounded-lg mb-4">
        <div class="text-sm text-gray-500">支付金额</div>
        <div class="text-xl font-bold text-[#F52735] mt-1">¥{{ amount }}</div>
      </div>

      <van-radio-group v-model="selectedPayType">
        <van-loading v-if="configLoading" class="py-8 text-center" />
        <van-cell-group v-else class="rounded-lg overflow-hidden">
          <van-cell
            v-for="item in filteredPaymentList"
            :key="item.type"
            clickable
            :title="item.name"
            @click="selectedPayType = item.type"
            class="flex items-center"
          >
            <template #icon>
              <img :src="item.icon" class="h-5 mr-2">
            </template>
            <template #right-icon>
              <van-radio :name="item.type" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

      <div class="mt-4">
        <van-button
          round
          block
          color="#F52735"
          :loading="loading"
          @click="onConfirm"
        >
          确认支付
        </van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Cell, CellGroup, Radio, RadioGroup, Popup, Button, Icon } from 'vant'
import { mapState } from 'vuex'
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: 'PaymentDialog',

  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Icon.name]: Icon
  },

  props: {
    value: Boolean,
    amount: {
      type: [Number, String],
      default: 0
    }
  },

  data() {
    return {
      selectedPayType: 3,
      loading: false,
      systemConfig: {},
      paymentList: [
        {
          name: '银联',
          icon: require('@/assets/img/payment/union.png'),
          type: 3,
          is_show: 'yun_pay_enable',
        },
        {
          name: '支付宝',
          icon: require('@/assets/img/payment/alipay.png'),
          type: 2,
          is_show: 'alipay_enable',
        },
        {
          name: '微信支付',
          icon: require('@/assets/img/payment/wxpay.png'),
          type: 1,
          is_show: 'wechat_enable',
        },
        {
          name: '银行卡',
          icon: require('@/assets/img/payment/alipay.png'),
          type: 5,
          is_show: 'bank_enable',
        },
        {
          name: '余额支付',
          icon: require('@/assets/img/payment/yue.png'),
          type: 9,
          is_show: 'balance_enable',
        },
      ],
      configLoading: false,
    }
  },

  computed: {
    ...mapState('systemInfo', ['systemInfo']),

    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    filteredPaymentList() {
      return this.paymentList.filter(item => this.systemConfig[item.is_show] == 1)
    }
  },

  watch: {
    show: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.getSystemConfig()
        }
      }
    }
  },

  methods: {
    onClose() {
      this.show = false
    },

    async onConfirm() {
      this.loading = true
      try {
        this.$emit('confirm', {
          payType: this.selectedPayType
        })
      } finally {
        this.loading = false
      }
    },

    async getSystemConfig() {
      this.configLoading = true
      try {
        const res = await  axios.get(api.systemInfo)
        if (res.data) {
          this.systemConfig = res.data
        }
      } catch (error) {
        console.log('获取支付配置失败:', error)
      } finally {
        this.configLoading = false
      }
    }
  }
}
</script>
