import { render, staticRenderFns } from "./activateProvince.vue?vue&type=template&id=18bfcce5&scoped=true"
import script from "./activateProvince.vue?vue&type=script&lang=js"
export * from "./activateProvince.vue?vue&type=script&lang=js"
import style0 from "./activateProvince.vue?vue&type=style&index=0&id=18bfcce5&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18bfcce5",
  null
  
)

export default component.exports