<template>
  <div class="h-full w-full bg-[#F0F2F6]">
    <van-overlay :show="pageLoading" class="flex items-center justify-center z-50">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </van-overlay>

    <div class="overflow-y-auto w-full relative page-content-2">
      <!-- 顶部区域 -->
      <div class="relative">
        <img src="@/assets/img/bg_page_top.png"
             class="absolute top-0 left-0 w-full h-[180px] object-cover flex-shrink-0"/>
        <div class="px-4 pt-8 relative z-10">
          <div class="text-white text-xl">资产结算</div>
          <div class="mt-4 text-white text-sm opacity-80">
            参加过项目关网跑路，改名，投资失败等<span
              style="color: #F0FF20;">任何原因导致资金亏损的都可以申请资产结算</span>，申请通过即可在中国平安结算资产提现。
          </div>
        </div>
      </div>

      <!-- 内容区域 -->
      <div class=" mt-4" style="margin-top: 8px;">
        <div class="flex-1 overflow-y-auto relative z-10 bg-[#F2F4F7] rounded-lg">
          <div class="mx-4 p-6 bg-white rounded-lg shadow-sm mt-4">
            <!-- 姓名输入 -->
            <div class="mb-6">
              <div class="text-[15px] mb-2">姓名</div>
              <input
                  v-model="form.name"
                  type="text"
                  placeholder="输入姓名"
                  class="w-full p-3 bg-[#F5F5F5] rounded-lg text-[14px] outline-none"
              />
            </div>

            <!-- 项目名输入 -->
            <div class="mb-6">
              <div class="text-[15px] mb-2">项目名（可多填）</div>
              <input
                  v-model="form.projects"
                  type="text"
                  placeholder="输入项目名称，用逗号分隔"
                  class="w-full p-3 bg-[#F5F5F5] rounded-lg text-[14px] outline-none"
              />
            </div>

            <!-- 金额输入 -->
            <div class="mb-8">
              <div class="text-[15px] mb-2">清算结算金额（结算额度{{ settleLimit }}）</div>
              <input
                  v-model.number="form.amount"
                  type="number"
                  @input="validateAmount"
                  placeholder="输入金额"
                  class="w-full p-3 bg-[#F5F5F5] rounded-lg text-[14px] outline-none"
              />
            </div>

            <!-- 按钮组 -->
            <div class="flex space-x-4">
              <button
                  @click="viewRecords"
                  class="flex-1 py-2 text-[#F23D30] border border-[#F23D30] rounded-lg text-[15px]">
                申请记录
              </button>
              <button
                  @click="submitForm"
                  class="flex-1 py-2 text-white bg-[#F23D30] rounded-lg text-[15px]">
                申请清算
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Loading, Overlay, Toast, Dialog} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {mapState} from 'vuex';

export default {
  name: "index",
  components: {
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Toast.name]: Toast,
    [Dialog.name]: Dialog,
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  data() {
    return {
      pageLoading: true,
      form: {
        name: '',
        projects: '',
        amount: ''
      },
      settleLimit: 0
    };
  },
  mounted() {
    this.pageLoading = false;
    // Set the default name from userInfo when component is mounted
    if (this.userInfo?.real_name) {
      this.form.name = this.userInfo.real_name;
    }
    this.getSettleLimit();
  },
  methods: {
    validateForm() {
      if (!this.form.name.trim()) {
        Toast('请输入姓名');
        return false;
      }
      if (!this.form.projects.trim()) {
        Toast('请输入项目名称');
        return false;
      }
      if (!this.form.amount || this.form.amount <= 0) {
        Toast('请输入有效的结算金额');
        return false;
      }
      return true;
    },
    async submitForm() {
      if (!this.validateForm()) {
        return;
      }

      try {
        this.pageLoading = true;
        await axios.post(api.user_settled_apply, {
          real_name: this.form.name,
          link_ido_name: this.form.projects,
          apply_settled_amount: this.form.amount
        });
        await Dialog.alert({
          title: '提示',
          message: '您的资产结算申请已提交成功',
          confirmButtonText: '确定',
          confirmButtonColor: '#F23D30'
        });
        // 成功后清空表单
        this.form = {
          name: this.userInfo?.real_name || '',
          projects: '',
          amount: ''
        };

      } catch (error) {
        await Dialog.alert({
          title: '提示',
          message: error.msg||'网络异常，请稍后重试',
          confirmButtonText: '确定',
          confirmButtonColor: '#F23D30'
        });
      } finally {
        this.pageLoading = false;
      }
    },
    async viewRecords() {
      try {
        this.$router.push({name: 'settlementRecords'});
      } catch (err) {
        Toast.fail('获取记录失败，请重试');
      }
    },
    validateAmount(e) {
      // 移除非数字字符
      let value = e.target.value.replace(/[^\d]/g, '');
      // 使用动态限额
      if (value > this.settleLimit) {
        value = this.settleLimit;
        Toast(`金额不能超过${this.settleLimit}`);
      }
      this.form.amount = value ? Number(value) : '';
    },
    async getSettleLimit() {
      try {
        const res = await axios.get(api.userWallet);
        if (res.code === 200 && res.data) {
          const walletInfo = {};
          res.data.coinList.forEach(coin => {
            walletInfo[coin.name_en] = coin;
          });
          // 使用 assets_quota 的��度
          if(walletInfo.assets_quota) {
            this.settleLimit = walletInfo.assets_quota.num;
          }
        }
      } catch (err) {
        console.log('获取结算额度失败', err);
      }
    },
  }
};
</script>

<style scoped>
.page-content-2 {
  height: calc(100vh - 50px);
}
</style>
