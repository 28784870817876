<template>
  <div class="page-container h-screen">
    <nav-bar
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent"
        :border="false"
    />
    <div class="contain-page">
      <div class="app-logo">
        <img src="../../assets/img/ic_launcher.png">
      </div>


      <div v-if="code" class="share-container">
        <div class="share-content bg-white rounded-lg px-4 py-4 flex flex-row justify-self-center">
          <div>
            <div id="qrcode" class="my-2"></div>
            <div class="text-center text-sm text-gray-500">图片分享给好友一起赚钱</div>
          </div>
          <div class="ml-4">
            <p class="text" style="font-size: 20px"> {{ code }}</p>
            <p class="text self-center"> 邀请码</p>
            <button @click="onCopyText(code)" class="w-full bg-red-500 text-white text-base rounded p-2 mt-2">
              复制邀请码
            </button>
            <button @click="onCopyText(url)" class="w-full bg-blue-500 text-white text-base rounded p-2 mt-2">
              复制推广链接
            </button>
          </div>
        </div>
      </div>

      <div class="download-container">
        <a class="download-btn" href="javascript:;" target="_blank" id="install_btn" @click="onDownload">立即下载</a>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import {NavBar,} from "vant";
import axios from "@/net/axios";

export default {
  name: "InviteUser",
  components: {NavBar,},
  data() {
    return {
      code: "",
      shareUrl: "",
      shouldGenerateQRCode: false,
      url: '',
      config: null,
    };
  },
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.loadConfig();
    });
  },


  methods: {
    onClickLeft() {
      // 检查是否有上一页历史记录
      if (window.history.length > 1 && document.referrer.includes(window.location.host)) {
        this.$router.go(-1);
      } else {
        // 没有历史记录或者是直接访问的，跳转到首页
        this.$router.push('/');
      }
    },
    onCopyText(text) {
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！')
    },
    async loadConfig() {
      try {
        // 获取配置
        const configRes = await fetch('/config.prod.json');
        this.config = await configRes.json();
        const { code } = this.$route.query;
        if (code) {
          this.code = code;
          await this.generateQRCode();
        }
      } catch (error) {
        console.log('加载配置失败:', error);
        this.$toast('加载配置失败');
      }
    },
    async generateQRCode() {
      try {
        if (!this.config) {
          this.$toast('配置未加载');
          return;
        }
        await this.$nextTick();  // 等待DOM更新
        // 清空容器元素中的内容
        const qrcodeContainer = document.getElementById("qrcode");
        if (!qrcodeContainer) {
          this.$toast('无法生成二维码：容器元素不存在');
          return;
        }

        qrcodeContainer.innerHTML = '';
        this.url = `${this.config.WEB_URL}/register?code=${this.code}`;
        new QRCode(qrcodeContainer, {
          text: this.url,
          width: 120,
          height: 120
        });
      } catch (err) {
        console.log('生成二维码失败:', err);
        this.$toast('生成二维码失败');
      }
    },
    async onDownload() {
      try {
        if (!this.config) {
          this.$toast('配置未加载');
          return;
        }
        // 使用配置中的地址
        const apiUrl = this.config.API_URL;
        const appUrl = this.config.APP_URL;

        // 下载计数
        await axios.get(`${apiUrl}/site/downcount`);

        // 用户下载记录
        const vuex_token = localStorage.getItem('vuex_token');
        if(vuex_token) {
          await axios.get(`${apiUrl}/user/appdownload`, {
            headers: { "token": vuex_token }
          });
        }

        // 根据设备类型设置下载链接
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.location.href = `${apiUrl}/download/app.mobileconfig`;
        } else {
          window.location.href = appUrl;
        }
      } catch (err) {
        console.error('下载失败:', err);
        this.$toast('下载失败，请稍后重试');
      }
    },
  },


}
</script>

<style scoped lang="less">
.contain-page {
  max-width: 750px;
  margin: 0 auto;
  min-height: 100vh;
  background: url('../../assets/img/bg_bottom.png') no-repeat bottom center;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-logo {
  margin-top: 50px;
  width: 80px;
  text-align: center;

  img {
    display: block;
    width: 100%;
  }
}

.app-name {
  margin-top: 15px;
  color: #FF5442;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.165px;
}

.share-container {
  width: 100%;
  padding: 0 16px;
  margin-top: 90px;
}

.share-content {
  width: 100%;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.download-container {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0 16px;
}

.download-btn {
  display: inline-block;
  width: 245px;
  height: 44px;
  border-radius: 22px;
  background: #FFF;
  color: #F8442D;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(248,68,45,0.2);
}

.page-container /deep/ .van-nav-bar .van-icon {
  color: #000;
}
</style>
