<template>
  <div class="login_box">
    <img
      src="../assets/img/login/bg_login.png"
      alt="bg"
      class="absolute top-0 left-0 w-full object-cover"
      style="height: 280px"
    />

    <div class="relative z-10 pt-10">
      <div class="px-5 my-6">
        <img src="../assets/img/login/ic_logo.png" alt="logo" class="w-[120px] h-[40px]"/>
      </div>
      
      <div class="mx-5">
        <div class="form-container">
          <div class="mb-1 login-title">登录</div>
          <div class="text-[#86909C] text-base mb-12">登录您的账户</div>
          
          <div class="space-y-8">
            <div class="input-item flex items-center">
              <div class="text-[#4E5969] w-20 whitespace-nowrap">手机号</div>
              <input
                class="flex-1 h-12 px-4 bg-[#F2F3F5] rounded-lg text-[#1D2129] text-lg focus:outline-none placeholder-[#86909C] max-w-[calc(100%-5rem)]"
                v-model="mobile"
                maxlength="11"
                placeholder="请输入手机号">
            </div>

            <div class="input-item flex items-center">
              <div class="text-[#4E5969] w-20 whitespace-nowrap">密码</div>
              <input
                class="flex-1 h-12 px-4 bg-[#F2F3F5] rounded-lg text-[#1D2129] text-lg focus:outline-none placeholder-[#86909C] max-w-[calc(100%-5rem)]"
                v-model="password"
                type="password"
                placeholder="请输入密码">
            </div>
          </div>

          <button
            class="w-full h-[48px] mt-12 text-white rounded-lg login-btn text-lg font-medium"
            @click="onLogin">
            登录
          </button>

          <div 
            class="w-full text-center mt-6 cursor-pointer"
            @click="jumpToPage('register')">
            <span class="text-[#86909C]">没有账号？</span>
            <span class="text-[#FF4D4F]">注册</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import {isNullOrEmpty} from "../utils";
import UserStorage from "@/utils/user_storage";

export default {
  name: "Login",
  data() {
    return {
      mobile: "",
      password: "",
      loading: false,
    };
  },
  mounted() {
    this.loginByToken();
  },
  methods: {
    loginByToken() {
      try {
        const token = this.$route.query.token;
        if (token) {
          UserStorage.setToken(token);
          window.token = token;
          this.$router.replace({name: "home"});
        }
      } catch (e) {
        console.log(e);
      }
    },
    onLogin() {
      if (isNullOrEmpty(this.mobile)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isNullOrEmpty(this.password)) {
        this.$toast("请输入密码");
        return;
      }
      this.$toast.loading({
        message: "登录中...",
        forbidClick: true,
      });

      const formData = new FormData();
      formData.append('account', this.mobile);
      formData.append('password', this.password);
      axios
          .post(api.login, formData)
          .then((res) => {
            this.$toast.clear();
            console.log("登录成功", res);
            UserStorage.setToken(res.data.token);
            window.token = res.data.token;
            this.$router.replace({name: "home"});
          })
          .catch((e) => {
            console.log('登录失败', e)
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },
    jumpToPage(name) {
      this.$router.push({name});
    },
  },
};
</script>
<style scoped lang="less">
.login_box {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  position: relative;

  .form-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 32px 24px;
  }

  .login-title {
    color: #F33;
    font-family: "PingFang SC";
    font-size: 30px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.3px;
  }

  input::placeholder {
    color: #86909C;
  }

  input {
    border: 1px solid transparent;
    transition: all 0.3s;
    
    &:focus {
      border-color: #FF4D4F;
      background-color: #fff;
    }
  }

  .login-btn {
    border-radius: 8px;
    background: linear-gradient(36deg, #F91126 7.89%, #FF813A 91.45%);
  }
}
</style>
