<template>
  <div class="h-full w-full bg-[#F0F2F6]">
    <van-overlay :show="pageLoading" class="flex items-center justify-center z-50">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </van-overlay>

    <div class="overflow-y-auto w-full relative page-content-2">
      <!-- 顶部区域 -->
      <div class="relative">
        <img src="@/assets/img/bg_page_top.png"
             class="absolute top-0 left-0 w-full h-[180px] object-cover flex-shrink-0"/>
        <div class="px-4 pt-8 relative z-10">
          <div class="text-white text-xl">保险基金</div>
          <div class="mt-4 text-white text-base opacity-80">
            激活中国平安保险基金是您一生的保障，未来的日子中国平安为您报价护航！
          </div>
        </div>
      </div>

      <!-- 内容区域 -->
      <div class=" mt-4" style="margin-top: 8px;">
        <div class="flex-1 overflow-y-auto relative z-10 bg-[#F2F4F7] rounded-lg">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <!-- 基金优选标题 -->
            <div class="px-4 pt-4 pb-2 flex items-center ">
              <div class="text-base font-medium">基金优选</div>
              <div class="text-xs text-gray-400 ml-2">科学评价、大数据基金严选</div>
            </div>

            <div class="bg-white rounded-lg mx-4 mb-2">
              <div class="px-4 py-3">
                <span class="px-3 py-1 inline-block text-center rounded"
                      style="background: linear-gradient(90deg, #F23D30 -1.33%, #FF6A57 49.31%, #F23D30 96%);
                             filter: drop-shadow(0px 1px 6.5px rgba(254, 178, 178, 0.25));
                             color: #FFF;
                             font-family: 'Abhaya Libre ExtraBold';
                             font-size: 14px;
                             font-weight: 800;
                             line-height: normal;
                             letter-spacing: -0.3px;">国海研选</span>
              </div>
              <div class="h-[1px] bg-gray-100 mx-2"></div>
              <div class="divide-y divide-gray-100 mx-2">
                <div v-for="(item, index) in list" :key="index" class="px-2 py-4">
                  <div class="flex">
                    <img :src="item.img" class="w-[74px] h-[74px] mr-2 flex-shrink-0 rounded-[6px]"
                         style="background: lightgray 0px 0px / 100% 100% no-repeat;"/>
                    <div class="flex-1 min-w-0">
                      <div class="flex items-center overflow-hidden">
                        <span class="text-base truncate">{{ item.name }}</span>
                        <span
                            class="ml-2 px-2 py-0.5 text-xs text-green-500 bg-green-50 rounded flex-shrink-0">低风险</span>
                      </div>

                      <div class="flex mt-4 text-center space-x-2">
                        <div class="flex-1 min-w-0">
                          <div class="text-[#F34947] font-medium truncate">+{{ item.gift_coin_num }}</div>
                          <div class="text-xs text-gray-400 mt-1 break-words">提现额度</div>
                        </div>
                        <div class="flex-1 min-w-0">
                          <div class="text-[#F34947] font-medium truncate">+{{ item.day_income }}</div>
                          <div class="text-xs text-gray-400 mt-1 break-words leading-tight">原金卡每日提现额度</div>
                        </div>
                        <div class="flex-1 min-w-0">
                          <div class="text-[#F34947] font-medium truncate">+{{ item.day_income }}</div>
                          <div class="text-xs text-gray-400 mt-1 break-words">每日分红</div>
                        </div>
                      </div>

                      <div class="flex items-center justify-between mt-4">
                        <span class="text-xl font-medium truncate mr-2">¥ {{item.price}}</span>
                        <button class="flex-shrink-0 px-4 py-1 text-white text-sm rounded shadow-sm"
                                style="background: linear-gradient(90deg, #F23D30 -1.33%, #FF6A57 49.31%, #F23D30 96%);"
                                @click="handleImageClick(item)">立即激活
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </div>
      </div>
    </div>

    <!-- 支付弹窗 -->
    <payment-dialog
        v-model="showPayment"
        :amount="currentItem?.price"
        @confirm="handlePayConfirm"
    />
  </div>
</template>

<script>
import {Icon, Loading, Overlay, PullRefresh, Dialog, Toast} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {mapState, mapActions} from "vuex";
import PaymentDialog from '@/components/common/PaymentDialog.vue';

export default {
  name: "index",
  components: {
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Dialog.Component.name]: Dialog.Component,
    PaymentDialog
  },
  data() {
    return {
      list: [],
      loading: false,
      refreshing: false,
      pageLoading: true,
      showPayment: false,
      currentItem: null,
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  async mounted() {
    await this.getUserInfo()
    await this.loadMore()
  },

  methods: {
    ...mapActions("user", ["getUserInfo"]),

    onRefresh() {
      this.refreshData()
    },
    refreshData() {
      this.refreshing = true
      this.getUserInfo()
      this.loadMore()
    },
    async loadMore() {
      try {
        const res = await axios.get(api.welfareList, {params: {type: 1}})
        this.list = res.data.idoList || []
      } catch (error) {
        Toast(error.msg || '加载失败')
      } finally {
        this.loading = false;
        this.refreshing = false;
        this.pageLoading = false;
      }
    },

    async handleImageClick(item) {
      if (!this.userInfo?.is_identify) {
        await Dialog.alert({
          message: '请先完成实名认证',
          confirmButtonText: '去认证',
          confirmButtonColor: '#F34947',
        })
        this.$router.push({name: 'realNameAuth'})
        return
      }

      // 直接调用支付接口，不显示支付对话框
      const params = {
        pay_type: 8,
        project_id: item.id,
        source: 2,
        amount: item.price,
      }

      try {
        const loading = Toast.loading({
          message: '处理中...',
          forbidClick: true,
        })

        const res = await axios.post(api.balancePay, params)
        loading.clear()

        if (res.data.url) {
          window.location.href = res.data.url
        } else {
          await this.$dialog.alert({
            title: '提示',
            message: '支付成功',
            confirmButtonText: '确定',
            confirmButtonColor: '#F34947',
          })
          this.refreshData()
        }
      } catch (error) {
        console.error(error)
        Toast.fail('支付失败')
      }
    },

    async handlePayConfirm({payType}) {
      const params = {
        pay_type: payType,
        project_id: this.currentItem.id,
        source: 2,
        amount: this.currentItem.price,
      }

      try {
        const loading = Toast.loading({
          message: '处理中...',
          forbidClick: true,
        })

        const res = await axios.post(api.balancePay, params)
        loading.clear()

        if (res.data.url) {
          window.location.href = res.data.url
        } else {
          await this.$dialog.alert({
            title: '提示',
            message: '激活成功！',
            theme: 'round-button',
            confirmButtonText: '确定',
            confirmButtonColor: '#F52735'
          })
          this.showPayment = false
          this.loadMore()
          await this.getUserInfo()
        }
      } catch (err) {
        this.$toast(err.msg || '支付失败')
      }
    },
  },
};
</script>

<style scoped lang="less">
.top-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.custom-loading {
  :deep(.van-pull-refresh__head) {
    .van-loading {
      .van-loading__spinner {
        color: #fff !important;
      }

      .van-loading__text {
        color: #fff !important;
      }
    }

    .van-pull-refresh__text {
      color: #fff !important;
    }
  }
}

.map-container {
  width: 100%;
  height: 200px; // 减小高度
  border-radius: 10px;
}
</style>
