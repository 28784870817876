import { render, staticRenderFns } from "./bindBankCard.vue?vue&type=template&id=06d1159f&scoped=true"
import script from "./bindBankCard.vue?vue&type=script&lang=js"
export * from "./bindBankCard.vue?vue&type=script&lang=js"
import style0 from "./bindBankCard.vue?vue&type=style&index=0&id=06d1159f&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d1159f",
  null
  
)

export default component.exports