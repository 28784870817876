<template>
  <div class="min-h-screen w-full bg-[#F0F2F6]">
    <!-- loading遮罩 -->
    <div v-if="firstLoading" class="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </div>

    <!-- 顶部区域 -->
    <div class="relative">
      <img src="@/assets/img/bg_page_top.png" class="absolute top-0 left-0 w-full h-[180px] object-cover flex-shrink-0" />
      <div class="px-4 pt-4 pb-4 relative z-10">
        <div class="flex items-center" @click="onClickLeft">
          <van-icon name="arrow-left" class="text-white text-xl mr-2" />
          <div class="text-white text-xl">团队中心</div>
        </div>
      </div>
    </div>

    <!-- 将PullRefresh移到团队奖励模块开始的位置 -->
    <PullRefresh v-model="refreshing" @refresh="refreshData">
      <!-- 团队奖励模块 -->
      <div class="px-4  relative z-20 mb-4">
        <div class="bg-white rounded-lg p-4">
          <div class="flex items-center mb-4">
            <div class="flex flex-col items-center">
              <span class="text-[#F23D30] font-medium text-xl">每日提现额度</span>
              <div class="w-[20px] h-[3px] bg-[#F23D30] mt-1 rounded-[8px]"></div>
            </div>
          </div>

          <div class="space-y-3">
            <div v-for="(item, index) in rewardList" :key="index"
                 class="flex items-start bg-[#FFF7F7] rounded-[10px] p-3 relative">
              <img :src="item.icon"
                   class="w-[81px] h-[81px] flex-shrink-0 rounded-[6px]"/>
              <div class="flex-1 ml-3">
                <div class="text-[#333333] text-[14px] whitespace-nowrap">
                  {{item.describe}}
                </div>
                <div class="flex flex-col mt-2">
                  <div class="flex items-baseline">
                    <span class="text-[#FF1D1D] ml-1 font-[Alata] text-[14px]">+</span>
                    <span class="text-[#FF1D1D] font-[Alata] text-[16px]">{{item.amount}}</span>
                    <span class="text-[#FF1D1D] ml-1 font-[Alata] text-[14px]">元</span>
                  </div>
                  <div class="text-[#999999] text-[12px] mt-[2px]">每日提现额度</div>
                </div>
              </div>
              <button @click="handleRewardClick(item)"
                      class="absolute right-3 bottom-3 w-[76px] h-[23px] flex items-center justify-center rounded-[4px]"
                      :class="getButtonClass(item.status)">
                <span class="text-white text-[14px]">{{getButtonText(item.status)}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 内容区域 -->
      <div class="px-4 relative z-20">
        <List v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMore">
          <div class="bg-white rounded-lg p-4">
            <!-- 统计卡片 -->
            <div class="flex items-center mb-4">
              <div class="flex flex-col items-center">
                <span class="text-[#F23D30] font-medium text-xl">团队中心</span>
                <div class="w-[20px] h-[3px] bg-[#F23D30] mt-1 rounded-[8px]"></div>
              </div>
            </div>

            <div class="flex justify-center items-center mb-6">
              <div class="flex justify-between w-full p-4" style="border-radius: 8px; background: var(--Style, linear-gradient(92deg, #FFC195 2.08%, #FF7070 97.09%));">
                <div class="text-center">
                  <div class="stats-number">{{ childCount?.zhi_num || '0' }}</div>
                  <div class="text-[#fff] text-sm mt-1">直推人数</div>
                </div>
                <div class="text-center">
                  <div class="stats-number">{{ childCount?.group_num || '0' }}</div>
                  <div class="text-[#fff] text-sm mt-1">团队总人数</div>
                </div>
                <div class="text-center">
                  <div class="stats-number">{{ childCount?.totalAchievement || '0.00' }}</div>
                  <div class="text-[#fff] text-sm mt-1">总业绩(元)</div>
                </div>
              </div>
            </div>

            <!-- Tab列表 -->
            <van-tabs v-model="activeTab" @click="onClick" class="custom-tabs">
              <van-tab v-for="tab in tabs" :key="tab.name" :title="tab.title" :name="tab.name">
                <div class="min-h-[200px]">
                  <div class="py-4 text-[#333] font-medium">
                    {{ tab.title }}({{ total || 0 }}人)
                  </div>

                  <!-- 空状态 -->
                  <van-empty
                    v-if="list.length === 0 && !loading"
                    class="custom-empty"
                    image="search"
                    description="暂无相关数据"
                  />

                  <!-- 列表内容 -->
                  <template v-else>
                    <div v-for="(item, index) in list" :key="index"
                         class="flex items-center p-4 border-b border-[#EEEEEE]">
                      <van-image
                        :radius="8"
                        width="50px"
                        height="50px"
                        :src="item.head_img || require('@/assets/img/d-avatar.png')"
                      />
                      <div class="ml-4 flex-1">
                        <div class="flex justify-between items-center">
                          <div class="flex items-center">
                            <span class="mr-2 text-[#333] font-medium">{{ item.real_name }}</span>
                            <span class="text-[#999] text-sm">{{ item.username }}</span>
                          </div>
                          <span class="text-[#999] text-sm">{{ item.created_at | timeFormat('YYYY/MM/DD') }}</span>
                        </div>
                        <div class="mt-2">
                          <span class="text-[#333] text-sm">{{ item.achievement }}元</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </van-tab>
            </van-tabs>
          </div>
        </List>
      </div>
    </PullRefresh>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import { mapActions, mapState } from "vuex";
import { Empty, Image as VanImage, List, NavBar, PullRefresh, Tab, Tabs } from "vant";
import QRCode from "qrcodejs2";

export default {
  name: "index",
  components: { NavBar, List, VanImage, Empty, PullRefresh, [Tab.name]: Tab, [Tabs.name]: Tabs },
  data() {
    return {
      count: 0,
      pageSize: 10,
      pageNo: 1,
      level:1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      childCount: {},
      activeTab: '0',
      tabs: [
        { title: '1级会员', name: '0' },
        { title: '2级会员', name: '1' },
        { title: '3级会员', name: '2' }
      ],
      inviteCode: "",
      inviteUrl: "",
      firstLoading: true,
      total: 0,
      rewardList: [], // 团队奖励列表
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return "我的团队";
    }
  },
  mounted() {
    this.getUserInfo().then(() => {
      if (this.userInfo && this.userInfo.code) {
        this.inviteCode = this.userInfo.code;
        this.inviteUrl = `${this.$config.WEB_URL}/register?code=${this.inviteCode}`;
        this.$nextTick(() => {
          this.generateQRCode();
        });
      }
    });
    this.loadMore();
    this.getRewardList();
  },
  watch: {
    userInfo: {
      handler(newVal, oldVal) {
        if (newVal.isCaptain !== oldVal?.isCaptain) {
          this.getTeamStatistics()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onClick(name) {
      this.level = parseInt(name) + 1;
      this.resetList();
    },
    resetList() {
      this.list = [];
      this.pageNo = 1;
      this.finished = false;
      this.loading = false;
      this.firstLoading = true;
      this.loadMore();
    },
    refreshData() {
      this.resetList();
      this.refreshing = false;
    },
    loadMore() {
      if(this.refreshing) {
        this.list = [];
      }

      this.loading = true;
      axios.get(api.teamList, {
        params: {
          level: this.level,
          page: this.pageNo,
          size: this.pageSize,
        }
      })
      .then((res) => {
        this.firstLoading = false;

        if(this.pageNo === 1) {
          this.list = res.data.list;
          this.childCount = res.data.userInfo || {};
        } else {
          this.list = [...this.list, ...res.data.list];
        }
        this.total = res.data.total;

        // 判断是否加载完成
        this.finished = this.list.length >= this.total;
        this.loading = false;
        if (this.list.length > 0) {
          this.pageNo++;
        }
      })
      .catch((err) => {
        this.firstLoading = false;
        this.loading = false;
        this.finished = true;
        err.msg && this.$toast(err.msg);
      });
    },
    generateQRCode() {
      const qrcodeContainer = document.getElementById("qrcode");
      if (qrcodeContainer && this.inviteUrl) {
        qrcodeContainer.innerHTML = '';
        new QRCode(qrcodeContainer, {
          text: this.inviteUrl,
          width: 62,
          height: 63,
          colorDark : "#000000",
          colorLight : "#ffffff",
          correctLevel : QRCode.CorrectLevel.H
        });
      }
    },
    onCopyText(text) {
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length);
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！')
    },
    // 获取团队奖励列表
    async getRewardList() {
      try {
        const res = await axios.get(api.myInviteVipCardAward)
        if (res.data?.inviteConfigList) {
          this.rewardList = res.data.inviteConfigList.map(item => ({
            icon: require('@/assets/img/invite/team_invite.png'),
            describe: item.describe,
            amount: item.invite_award_num,
            status: item.status
          }))
        }
      } catch (e) {
        console.error('获取团队奖励列表失败:', e)
      }
    },

    getButtonText(status) {
      switch (status) {
        case 1: return '去邀请'
        case 2: return '去领取'
        case 3: return '已领取'
        default: return '去邀请'
      }
    },

    getButtonClass(status) {
      switch (status) {
        case 1: return 'bg-gradient-to-r from-[#F23D30] via-[#FF6A57] to-[#F23D30]'
        case 2: return 'bg-gradient-to-r from-[#F23D30] via-[#FF6A57] to-[#F23D30]'
        case 3: return 'bg-[#999999]'
        default: return 'bg-gradient-to-r from-[#F23D30] via-[#FF6A57] to-[#F23D30]'
      }
    },

    async handleRewardClick(item) {
      switch(item.status) {
        case 1:
        case 3:
          this.$router.push({name: 'userInvite', query: {code: this.userInfo.code}})
          break
        case 2:
          try {
            const res = await axios.post(api.getVipInviteAward, { id: item.id })
            if(res.code === 0) {
              this.$toast('领取成功')
              await this.getRewardList()
            } else {
              this.$toast(res.msg || '领取失败')
            }
          } catch(e) {
            this.$toast(e.msg || '网络错误')
          }
          break
      }
    }
  },
};
</script>

<style scoped lang="less">
.custom-tabs {
  :deep(.van-tabs__wrap) {
    background: white;
    padding-top: 10px;
  }

  :deep(.van-tab) {
    color: #313131;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  :deep(.van-tabs__line) {
    width: 40.124px !important;
    height: 3px;
    background: #3C150D;
    bottom: 15px;
    border-radius: 1.5px;
  }

  :deep(.van-tabs__nav) {
    border: none;
  }

  :deep(.van-tabs__content) {
    background: white;
  }
}

.custom-empty {
  padding: 60px 0;

  :deep(.van-empty__image) {
    width: 120px;
    height: 120px;
  }

  :deep(.van-empty__description) {
    color: #999;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }
}

.stats-number {
  color: #EF0;
  text-align: center;
  font-family: zihunxingmouhei_trial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
</style>
