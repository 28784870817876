
<template>
  <div class="bg-top page-container h-screen bg-gradient-to-b from-red-500 to-red-600">
    <nav-bar
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
        :style="{ background: 'transparent', color: 'white', marginBottom:'80px' }"
    />
    <div class=" w-full p-4">
      <div class="text-white mb-2">
        <h1 class="text-3xl font-bold">实名认证</h1>
        <p class="mt-2">更加真实，更受欢迎</p>
      </div>
      <div class="relative mb-4">
        <img src="@/assets/img/profile/shield.png" alt="Shield" class=" absolute right-6 -top-7 z-10" style="height: 138px">
      </div>
      <div class="bg-white rounded-lg p-4">
        <p class="text-gray-700 mb-4">请您使用有效身份证信息认证</p>
        <form @submit.prevent="onSubmit">
          <div style="margin-top: 30px;margin-bottom: 30px;">
            <label class="block text-gray-700 text-sm mb-2">真实姓名</label>
            <input
                v-model="realname"
                type="text"
                class="w-full p-2 bg-gray-100 rounded"
                style="height: 60px"
                placeholder="请输入真实姓名"
                :readonly="isAuth"
            >
          </div>
          <div style="margin-top: 30px;margin-bottom: 30px;">
            <label class="block text-gray-700 text-sm mb-2">证件号码</label>
            <input
                v-model="idcard"
                type="text"
                class="w-full p-2 bg-gray-100 rounded"
                style="height: 60px"
                placeholder="请输入身份证号码"
                :readonly="isAuth"
            >
          </div>
          <div class="mt-4 text-gray-500 text-sm flex items-center">
            <img src="@/assets/img/profile/info-circle.png" alt="Info" class="w-4 h-4 mr-2">
            <span>实名仅用于您是否为真人用户，不会对信息做任何采集与保留，请放心使用。</span>
          </div>
          <div class="mt-8" v-if="!isAuth">
            <Button round block type="info" color="#FF5848" native-type="submit">确认</Button>
          </div>
          <div class="mt-8" v-if="isAuth">
            <Button round block type="info" color="#D1D4D8" disabled>已通过认证</Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Button, NavBar } from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import { isNullOrEmpty } from "@/utils";

export default {
  components: { NavBar, Button },
  computed: {
    ...mapState("user", ["identifyInfo"]),
    title() {
      return "实名认证";
    }
  },
  watch: {
    identifyInfo(newVal) {
      if (newVal) {
        this.isAuth = newVal.status == 1 || newVal.status == 2;
        this.realname = newVal.name;
        this.idcard = newVal.id_card_no;
      }
    },
  },
  mounted() {
    this.getIdentifyInfo();
  },
  data() {
    return {
      idcard: "",
      realname: "",
      isAuth: false,
    }
  },
  methods: {
    ...mapActions("user", ["getIdentifyInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit() {
      if (isNullOrEmpty(this.realname)) {
        this.$toast("请输入真实姓名");
        return;
      }
      const regName = /^[\u4e00-\u9fa5]+·?[\u4e00-\u9fa5]+$/;
      if (!regName.test(this.realname)) {
        this.$toast('真实姓名填写有误!');
        return;
      }
      if (isNullOrEmpty(this.idcard)) {
        this.$toast("请输入身份证号码");
        return;
      }
      const regExp = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;
      if (!regExp.test(this.idcard)) {
        this.$toast('身份证号填写有误!');
        return;
      }
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      axios.post(api.realAuth, {
        id_card_no: this.idcard,
        name: this.realname,
      }).then(() => {
        this.$toast.clear();
        this.$toast("实名认证成功");
        this.getIdentifyInfo();
        this.onClickLeft();
      }).catch((err) => {
        this.$toast.clear();
        err.msg && this.$toast(err.msg);
      });
    },
  },
}
</script>

<style scoped>

.bg-top {
  background: linear-gradient(0deg, #FAFAFA, #CF0010);
  background-size: 100% 50%;
  background-repeat: no-repeat;
}
.page-container /deep/ .van-nav-bar .van-icon {
  color: #fff;
}
.-top-7 {
  top: -6.75rem;
}
</style>