<template>
  <div class="h-full w-full flex flex-col">
    <!-- 标题栏 -->
    <nav-bar
      :title="title"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      :safe-area-inset-top="true"
      :border="false"
      class="fixed top-0 left-0 right-0 z-20"
    />

    <!-- 标题栏占位 -->
    <div class="h-[44px]"></div>

    <!-- Tab栏固定 -->
    <div class="sticky top-[44px] z-10 bg-white">
      <van-tabs v-model="activeTab" @click="onClick" class="custom-tabs">
        <van-tab title="每日分红" name="income_wallet" />
        <van-tab title="佣金钱包" name="commiss_wallet" />
        <van-tab title="现金钱包" name="cash_wallet" />
        <van-tab title="资产钱包" name="assets_wallet" />
        <van-tab title="黑卡额度" name="withdraw_wallet" />
      </van-tabs>
    </div>

    <!-- 列表内容区域 -->
    <div class="flex-1 overflow-auto">
      <PullRefresh v-model="refreshing" @refresh="refreshData" class="min-h-full">
        <List
          v-model="loading"
          :finished="finished"
          :finished-text="list.length > 0 || activatedList.length > 0 ? '没有更多了' : ''"
          @load="loadMore"
          :immediate-check="true"
          class="h-full"
          :loading-text="page > 1 ? '加载中...' : ''"
        >
          <!-- 首次加载中 -->
          <div v-if="firstLoading" class="py-8 text-center">
            <van-loading type="spinner" color="#FF5848" />
          </div>

          <!-- 无数据显示空视图 -->
          <van-empty
            v-else-if="list.length === 0 && activatedList.length === 0 && !loading && !firstLoading"
            class="custom-empty"
            image="search"
            description="暂无相关数据"
          />

          <!-- 列表内容 -->
          <template v-else>
            <div v-for="(group, date) in groupedList" :key="date" class="mb-4">
              <div class="bg-gray-200 px-4 py-2 rounded-t-lg">{{ date }}</div>
              <div v-for="(item, index) in group" :key="index" class="bg-white px-4 py-3 border-b">
                <div class="flex justify-between items-center">
                  <div>
                    <div class="text-base font-semibold">
                      <template v-if="activeTab === 'activity'">
                        {{ item.name }}
                      </template>
                      <template v-else>
                        {{ activeTab === 'env_wallet' ? item.name : item.content }}
                      </template>
                    </div>
                    <div class="text-sm text-gray-500">
                      <template v-if="activeTab === 'activity'">
                        <div>激活省份: {{ item.area }}</div>
                        <div>激活时间: {{ item.formattedTime }}</div>
                      </template>
                      <template v-else-if="activeTab === 'env_wallet'">
                        投资金额: {{ item.invest_money }}元
                      </template>
                      <template v-else>
                        余额:{{ Math.floor(item.after) }}{{ activeTab === 'stock' ? '股' : '元' }}
                      </template>
                    </div>
                  </div>
                  <div class="text-red-500 font-semibold">
                    <template v-if="activeTab === 'activity'">
                      分红: {{ item.income }}元
                    </template>
                    <template v-else-if="activeTab === 'env_wallet'">
                      分红: {{ (parseFloat(item.invest_money) * parseFloat(item.day_rate) / 100).toFixed(2) }}元
                    </template>
                    <template v-else>
                      {{ activeTab === 'stock' ? Math.floor(item.total) : item.total }}{{ activeTab === 'stock' ? '股' : '元' }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </List>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import { List, NavBar, PullRefresh, Tab, Tabs} from "vant";
import {EnumOperations, FundType, getFundTypeLabel} from "@/config";

export default {
  components: {NavBar, List,  PullRefresh, [Tab.name]: Tab, [Tabs.name]: Tabs},
  data() {
    return {
      getFundTypeLabel,
      FundType,
      title: this.$route.meta.title,
      totalPage: 0,
      size: 20,
      page: 1,
      list: [],
      activatedList: [],
      loading: false,
      finished: false,
      refreshing: false,
      firstLoading: false,
      activeTab: 'income_wallet',
      total: 0,
      isRequesting: false,
    };
  },
  computed: {
    groupedList() {
      if (this.activeTab === 'activity') {
        // Group activity list by date
        const groups = this.activatedList.reduce((groups, item) => {
          const date = this.$options.filters.timeFormat(item.created_at, 'YYYY-MM-DD');
          if (!groups[date]) {
            groups[date] = [];
          }
          // 添加格式化后的时间到每个项目
          groups[date].push({
            ...item,
            formattedTime: this.$options.filters.timeFormat(item.created_at, 'YYYY-MM-DD HH:mm')
          });
          return groups;
        }, {});

        return groups;
      }

      // Original grouping logic for other tabs
      return this.list.reduce((groups, item) => {
        const date = this.$options.filters.timeFormat(item.created_at, 'YYYY-MM-DD');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
    }
  },
  filters: {
    getTypes(value) {
      if (value == EnumOperations.INCREASE) {
        return '收入';
      }
      return '支出';
    },
    timeFormat(value, format = 'YYYY-MM-DD') {
      if (!value) return '';
      const date = new Date(value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      if (format === 'YYYY-MM-DD') {
        return `${year}-${month}-${day}`;
      } else if (format === 'YYYY-MM-DD HH:mm') {
        return `${year}-${month}-${day} ${hours}:${minutes}`;
      }
      return value;
    }
  },
  created() {
    // 接收路由参数,设置默认选中的 tab
    if(this.$route.query.type) {
      this.activeTab = this.$route.query.type;
    }
    // 组件创建后立即加载数据
    this.loadMore();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClick(name) {
      if (this.isRequesting) return; // 防止请求中切换
      if (typeof name === 'object') {
        name = name.name; // 处理 click-tab 事件传入的参数
      }

      this.activeTab = name;
      this.list = [];
      this.activatedList = [];
      this.page = 1;
      this.finished = false;
      this.loading = true; // 设置 loading 为 true 触发加载
      this.firstLoading = false;
      // 使用 nextTick 确保状态更新后再调用加载
      this.$nextTick(() => {
        this.loadMore();
      });
    },
    resetList() {
      if (this.isRequesting) return;
      this.list = [];
      this.activatedList = [];
      this.page = 1;
      this.finished = false;
      this.loading = false;
      this.firstLoading = false;
      this.loadMore();
    },
    refreshData() {
      if (this.isRequesting) return;
      this.resetList();
      this.refreshing = false;
    },
    async loadMore() {
      // 移除可能阻止首次加载的条件
      if (this.isRequesting) return;

      if (this.refreshing) {
        this.list = [];
        this.activatedList = [];
        this.page = 1;
      }

      this.isRequesting = true;

      try {
        if (this.activeTab === 'activity') {
          await this.getDetails();
        } else if (this.activeTab === 'env_wallet') {
          // env_wallet 的特殊处理
          const res = await axios.get(api.buyBonusList, {
            params: { type: 4 }
          });
          this.list = res.data.idoList || [];
          this.finished = true; // env_wallet 一次性加载所有数据
        } else {
          // 其他 tab 的处理
          const res = await axios.get(api.balanceLog, {
            params: {
              coin_type: this.activeTab,
              page: this.page,
              size: this.size
            }
          });

          if (res.data?.list) {
            if (this.refreshing) {
              this.list = res.data.list;
            } else {
              this.list = [...this.list, ...res.data.list];
            }

            this.page++;
            this.finished = this.list.length >= res.data.total;
          } else {
            this.finished = true;
          }
        }
      } catch (err) {
        err.msg && this.$toast(err.msg);
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
        this.firstLoading = false;
        this.isRequesting = false;
      }
    },
    maskName(name) {
      if (!name) return '';
      if (name.length <= 2) {
        return name.substr(0, 1) + '*';
      }
      return name.substr(0, 1) + '*'.repeat(name.length - 2) + name.substr(-1);
    },

    async getDetails() {
      try {
        this.loading = true;
        const userListRes = await axios.get(api.inviteProvinceUserList, {
          params: {
            page: this.page,
            size: this.size
          }
        });
        console.log('激活明细',userListRes);
        if (userListRes.data?.list) {
          const mergedData = userListRes.data.list.map(item => ({
            name: item.real_name ? this.maskName(item.real_name) : '未实名',
            phone: item.username,
            area: item.province,
            income: item.gift_coin_num,
            user_id: item.user_id,
            created_at: item.created_at
          }));

          if (this.refreshing) {
            this.activatedList = mergedData;
          } else {
            this.activatedList = [...this.activatedList, ...mergedData];
          }
          console.log('激活明细2',this.activatedList);
          this.page++;
          this.finished = this.activatedList.length >= userListRes.data.total;
        } else {
          this.finished = true;
        }
      } catch(err) {
        err.msg && this.$toast(err.msg);
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
// 自定义 tab 样式
.custom-tabs {
  :deep(.van-tabs__wrap) {
    background: white;
  }

  :deep(.van-tab) {
    color: #313131;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  :deep(.van-tab--active) {
    color: #FF5848;
    font-weight: 500;
  }

  :deep(.van-tabs__line) {
    width: 20px !important;
    height: 3px;
    background: #FF5848;
    border-radius: 1.5px;
  }

  :deep(.van-tabs__nav) {
    border: none;
  }
}

// 自定义空视图样式
.custom-empty {
  padding: 60px 0;

  :deep(.van-empty__image) {
    width: 120px;
    height: 120px;
  }

  :deep(.van-empty__description) {
    color: #999;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }
}

// 添加列表相关样式
:deep(.van-list) {
  min-height: 100%;
}

:deep(.van-pull-refresh) {
  min-height: 100%;
}

// 确保内容区域可以滚动
.overflow-auto {
  -webkit-overflow-scrolling: touch;
}
</style>
