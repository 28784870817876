<template>
  <div class="news-container bg-white mt-2">
    <div class="news-header">
      <div class="news-tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['tab-item', { active: currentTab === index }]"
          @click="switchTab(index)"
        >
          {{ tab }}
        </div>
      </div>
    </div>

    <div class="news-list">
      <div v-for="(item, index) in newsList"
           :key="index"
           @click="jumpToPage(item.id)"
           class="news-item">
        <div class="news-content">
          <div class="news-title">{{ item.title }}</div>
          <div class="news-desc" v-html="item.content.replace(/<[^>]+>/g, '')"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
export default {
  data() {
    return {
      currentTab: 0,
      tabs: ['新闻', '党建', '政策'],
      typeMap: {
        0: 3,  // 媒体新闻
        1: 4,  // 党建风采
        2: 5   // 国资动态
      },
      newsList: []
    };
  },
  mounted () {
    this.getNewsList();
  },
  methods: {
    switchTab(index) {
      this.currentTab = index;
      this.getNewsList();
    },
    jumpToPage(id) {
      this.$router.push({
        name: 'newsDetails',
        params: {
          id,
          type:1
        }
      })
    },

    getNewsList() {
      axios
        .get(api.newsList, {
          params: {
            type: this.typeMap[this.currentTab],  // 使用映射关系获取正确的type值
            index_status: 0
          }
        })
        .then((res) => {
          this.newsList = res.data;
        })
        .catch((err) => {
          err.msg && this.$toast(err.msg);
        });
    },
  },
};
</script>

<style scoped lang="less">
.news-container {
  padding: 16px;
  border-radius: 8px;
}

.news-tabs {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.tab-item {
  width: 72px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #646566;
  cursor: pointer;
  border-radius: 4px;

  &.active {
    background-color: #2E5CFF;
    color: #FFF;
    font-size: 17px;
  }
}

.news-item {
  padding: 12px;
  background: #F3F9FF;
  border-radius: 8px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.news-content {
  width: 100%;
}

.news-title {
  color: #252626;
  font-family: "PingFang SC";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-desc {
  color: #646566;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
