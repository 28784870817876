<template>
  <div class="w-full video-container">
    <video ref="videoPlayer"
           controls
           autoplay
           muted
           playsinline
           @loadedmetadata="handleMetadata"
           @canplaythrough="autoPlayVideo"
           class="video"
           :src='videoSrc' ></video>
  </div>
</template>


<script>
import  videoPath from '@/assets/video/news.mp4'

export default {
  data() {
    return {
      videoSrc: videoPath,
      videoHeight: 0
    };
  },
  methods: {
    autoPlayVideo() {
      this.$refs.videoPlayer.play()
    },
    handleMetadata() {
      const videoPlayer = this.$refs.videoPlayer
      const videoRatio = videoPlayer.videoWidth / videoPlayer.videoHeight
      this.videoHeight = this.$el.offsetWidth / videoRatio
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleMetadata)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleMetadata)
  }
};
</script>
<style scoped lang="less">
.video-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
}
.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
</style>
