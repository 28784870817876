<template>
  <div class="h-full w-full bg-[#F0F2F6]">
    <!-- loading遮罩 -->
    <van-overlay :show="pageLoading" class="flex items-center justify-center z-[9999]" style="position: fixed;">
      <van-loading type="spinner" color="#fff" size="36" vertical>加载中...</van-loading>
    </van-overlay>

    <div class="overflow-y-auto w-full relative page-content-2" style="position: relative; z-index: 1;">
      <!-- 顶部区域 -->
      <div class="relative">
        <img src="@/assets/img/bg_page_top.png" class="absolute top-0 left-0 w-full h-[180px] object-cover flex-shrink-0" />
        <div class="px-4 pt-8 relative z-10">
          <div class="text-white text-xl">我的</div>
        </div>
      </div>

      <!-- 内容区域 -->
      <div class="relative z-10 pb-[50px] opacity-0" :class="{ 'opacity-100 transition-opacity duration-300': !pageLoading }">
        <!-- 用户信息 -->
        <div class="user-info-container mx-4 mt-8">
          <div class="flex items-center justify-between">
            <!-- 左侧头像和信息 -->
            <div class="flex items-center">
              <img class="w-[44px] h-[44px] rounded-full"
                   @click="jumpToPage('userInfo')"
                   :src="userInfo.head_img?userInfo.head_img: require('@/assets/img/d-avatar.png')"
                   alt="">
              <div class="ml-3">
                <p class="text-white text-base font-medium 1">
                  {{ userInfo.real_name || '未实名' }}
                </p>
                <p class="phone-number">{{ userInfo.name|maskPhoneNumber }}</p>
                <div class="invite-code">
                  <span class="label">邀请码：</span>
                  <span class="code">{{ userInfo.code }}</span>
                </div>
              </div>
            </div>

            <!-- 右侧按钮 -->
            <div class="flex flex-col items-end">
              <img src="@/assets/img/profile/btn_sign.png"
                   class="w-[60px] h-[22px]"
                   @click="handleSignIn"
                   alt=""/>
              <div class="flex items-center mt-2 cursor-pointer copy-link" @click="jumpToPage('downloadApp')">
                <span style="color:#fff">邀请好友</span>
                <van-icon name="arrow" size="12" color="#fff"/>
              </div>
            </div>
          </div>
        </div>

        <!-- 资产卡片区域 -->
        <div class="px-4">
          <div class="mt-4">
            <div class="grid grid-cols-2 gap-4">
              <!-- 现金钱包 -->
              <div class="asset-card" @click="jumpToWithdraw(walletInfo.cash_wallet)">
                <div class="flex items-start">
                  <img src="@/assets/img/profile/icon_money.png" class="w-[20px] h-[20px] mt-[1px] flex-shrink-0"/>
                  <div class="flex flex-col flex-1">
                    <span class="amount">{{ walletInfo.cash_wallet?.num || '0.00' }}</span>
                    <div class="desc">现金钱包(元)</div>
                    <button class="withdraw-btn">马上提现</button>
                  </div>
                </div>
              </div>

              <!-- 清算资产钱包 -->
              <div class="asset-card" @click="jumpToWithdraw(walletInfo.assets_wallet)">
                <div class="flex items-start">
                  <img src="@/assets/img/profile/icon_asset.png" class="w-[20px] h-[20px] mt-[1px] flex-shrink-0"/>
                  <div class="flex flex-col flex-1">
                    <span class="amount">{{ walletInfo.assets_wallet?.num || '0.00' }}</span>
                    <div class="desc">清算资产钱包(元)</div>
                    <button class="withdraw-btn">马上提现</button>
                  </div>
                </div>
              </div>

              <!-- 每日分红 -->
              <div class="asset-card" @click="jumpToWithdraw(walletInfo.income_wallet)">
                <div class="flex items-start">
                  <img src="@/assets/img/profile/icon_bonus.png" class="w-[20px] h-[20px] mt-[1px] flex-shrink-0"/>
                  <div class="flex flex-col flex-1">
                    <span class="amount">{{ walletInfo.income_wallet?.num || '0.00' }}</span>
                    <div class="desc">每日分红(元)</div>
                    <button class="withdraw-btn">马上提现</button>
                  </div>
                </div>
              </div>

              <!-- 佣金钱包 -->
              <div class="asset-card" @click="jumpToWithdraw(walletInfo.commiss_wallet)">
                <div class="flex items-start">
                  <img src="@/assets/img/profile/icon_commission.png" class="w-[20px] h-[20px] mt-[1px] flex-shrink-0"/>
                  <div class="flex flex-col flex-1">
                    <span class="amount">{{ walletInfo.commiss_wallet?.num || '0.00' }}</span>
                    <div class="desc">佣金钱包(元)</div>
                    <button class="withdraw-btn">马上提现</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 活动图片 -->
        <div  @click="jumpToPage('userInvite')">
          <img src="@/assets/img/profile/activity.png" class="w-full rounded-[10px]"/>
        </div>

        <!-- 功能按钮组 -->
        <div class="mt-4 mx-4">
          <div class="common-functions-title">常用功能</div>
          <div class="grid grid-cols-4 gap-4">
            <div v-for="item in functionButtons" :key="item.id"
                 class="function-item flex flex-col items-center"
                 @click="jumpToPage(item.route)">
              <img :src="item.icon" class="w-[32px] h-[32px]" />
              <span class="text-[12px] mt-2">{{ item.name }}</span>
            </div>
          </div>
        </div>

        <!-- 退出登录按钮 -->
        <div class="mx-4 mb-4">
          <van-button block class="logout-btn" @click="onLoginOut">退出登录</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.page-content-2 {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  z-index: 1;
}

.van-overlay {
  position: fixed;
}

.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.page-content /deep/ .van-grid-item__text {
  font-size: 14px
}

.item-btn {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333435;
  margin: 20px auto auto;
}

.copy-link-btn {
  background: #FFE6C8;
  border-radius: 5px;
  font-family: MiSans;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #F14B47;
  padding: 5px 10px;
}

.bg-info {
  padding: 0 10px;
}

.kf-container {
  width: 61px;
  height: 78px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.user-info-container {
  margin: 12px 16px;
  padding: 16px 16px 0;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.info-text {
  font-size: 14px;
  color: #666;
}

.copy-btn {
  background: #FFE6C8;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 14px;
  color: #F14B47;
}

.sign-btn {
  height: 26px;
}

.invite-img {
  width: calc(100% - 32px);
  margin: 16px;
  border-radius: 10px;
}

// 加下拉刷新样式
.van-pull-refresh {
  overflow: visible;
}

.van-pull-refresh__head {
  .van-loading {
    .van-loading__spinner {
      color: #fff !important;
    }

    .van-loading__text {
      color: #fff !important;
    }
  }

  .van-pull-refresh__text {
    color: #fff !important;
  }
}

.amount-text {
  color: #D1645A;
  font-family: "PingFang SC";
  font-size: 15.89px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}

.asset-block {
  margin: 12px 16px;
  background: #FFFFFF;
  border-radius: 8px;
  overflow: hidden;
}

.total-asset {
  padding: 16px;
}

.divider {
  height: 1px;
  background: #F5F5F5;
  margin: 0 16px;
}

.resource-asset {
  padding: 16px;
}

.env-asset {
  margin: 12px 16px 0;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;
}

.action-btn {
  display: flex;
  width: 51px;
  height: 21px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  border-radius: 4px;
  font-size: 12px;
  color: #FFFFFF;

  &.withdraw {
    background: #FA4949;
  }

  &.detail {
    background: #BA8E8E;
  }
}

.fund-title {
  color: #B59F9D;
  font-family: "PingFang SC";
  font-size: 13.889px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-edge: cap;
  leading-trim: both;
}

.fund-amount {
  color: #333;
  font-family: "Yuanti SC";
  font-size: 21.528px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-edge: cap;
  leading-trim: both;
}

.copy-link {
  span {
    color: #563634;
    font-family: "PingFang SC";
    font-size: 11.111px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .van-icon {
    margin-left: 4px;
    color: #563634;
  }
}

.invite-code {
  .label {
    color: #fff;
    font-family: "PingFang SC";
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-edge: cap;
    leading-trim: both;
  }

  .code {
    color: #fff;
    font-family: "PingFang SC";
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-edge: cap;
    leading-trim: both;
  }
}

.phone-number {
  color: #fff;
  font-family: "Yuanti SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.common-tools {
  margin: 0 16px 12px;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;

  .title {
    font-family: "PingFang SC";
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 16px;
  }

  .tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .tool-icon {
      width: 52px;
      height: 52px;
      flex-shrink: 0;
    }

    .tool-text {
      color: #999;
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.other-tools {
  background: #FFFFFF;

  .title-bg {
    background: #ECF1F9;
    padding: 10px;

    .title {
      font-family: "PingFang SC";
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }

  .other-tools-list {
    padding: 0 16px;

    .tool-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      cursor: pointer;
      border-bottom: 1px solid #F5F5F5;

      &:last-child {
        border-bottom: none;
      }

      .other-tool-icon {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }

      .other-tool-text {
        color: #2A3563;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.24px;
        margin-left: 12px;
      }
    }
  }
}

.env-wallet {
  margin: 12px 16px 0;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;

  .wallet-icon {
    width: 20px;
    height: 20px;
  }

  .wallet-title {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px;
  }
}

.asset-item {
  padding: 12px;
  background: #F8F8F8;
  border-radius: 8px;
}

.withdraw-btn {
  padding: 4px 12px;
  background: #F23D30;
  border-radius: 4px;
  color: white;
  font-size: 14px;
}

.function-item {
  cursor: pointer;
  border-radius: 8px;
  background: #FFF;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
  }

  span {
    color: #333;
    font-size: 12px;
    margin-top: 8px;
  }

  &:active {
    opacity: 0.8;
  }
}

.asset-card {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 12px;

  .amount {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    margin-left: 8px;
    line-height: 20px;
  }

  .desc {
    font-size: 12px;
    color: #999999;
    margin: 8px 0 8px 8px;
  }

  .withdraw-btn {
    width: 80px;
    height: 32px;
    background: #F23D30;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
}

// 添加退出按钮样式
.logout-btn {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background: linear-gradient(90deg, #9D9D9D 0%, #C9C9C9 48.44%, #979797 100%);
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;

  &:active {
    opacity: 0.9;
  }
}

// 修改功能按钮组容器样式
.mx-4.mt-4.grid {
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  background: #fff;
  border-radius: 12px;
  padding: 16px;
}

.common-functions-title {
  font-family: "PingFang SC";
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 16px;
}
</style>

<script>
import {mapActions, mapState} from "vuex";
import {WithdrawalType} from "@/config";
import axios from "@/net/axios";
import api from "@/net/api";
import {Loading, Overlay, PullRefresh} from "vant";

export default {
  name: "index",
  components: {
    [PullRefresh.name]: PullRefresh,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  computed: {
    ...mapState("user", ["userInfo", "userWallet"]),
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    },
    displayName: function () {
      if (this.userInfo.real_name) {
        return this.userInfo.real_name;
      } else if (this.userInfo.name) {
        return this.userInfo.name;
      } else {
        return "未设置";
      }
    },
  },
  data() {
    return {
      invitaCode: "",
      shareUrl: "",
      shouldGenerateQRCode: false,
      WithdrawalType,
      walletInfo: {
        cash_wallet: {
          amount: '0.00',
          title: '现金钱包',
          type: 'cash_wallet'
        },
        assets_wallet: {
          amount: '0.00',
          title: '清算资产钱包',
          type: 'assets_wallet'
        },
        income_wallet: {
          amount: '0.00',
          title: '每日分红',
          type: 'income_wallet'
        },
        commiss_wallet: {
          amount: '0.00',
          title: '佣金钱包',
          type: 'commiss_wallet'
        }
      },
      recommendRecords: [],
      refreshing: false,
      loadingIcon: 'loading',
      pageLoading: true,
      // 常用功能数据
      commonTools: [
        {
          id: 1,
          name: '认证中心',
          icon: require('@/assets/img/profile/icon_auth.png'),
          route: 'realNameAuth'
        },
        {
          id: 2,
          name: '客服中心',
          icon: require('@/assets/img/profile/icon_service.png'),
          route: 'customService'
        },
        {
          id: 3,
          name: '银行绑定',
          icon: require('@/assets/img/profile/icon_bank.png'),
          route: 'bindBankCard'
        },
        {
          id: 4,
          name: '投资中心',
          icon: require('@/assets/img/profile/icon_invest.png'),
          route: 'envWallet'
        },
        {
          id: 5,
          name: '提现记录',
          icon: require('@/assets/img/profile/icon_withdraw.png'),
          route: 'fundDetail'
        },
        {
          id: 6,
          name: '团队中心',
          icon: require('@/assets/img/profile/icon_team.png'),
          route: 'team'
        }
      ],

      // 其他功能数据
      otherTools: [
        {
          id: 1,
          name: '修改密码',
          icon: require('@/assets/img/profile/icon_mdf_pwd.png'),
          route: 'modifyPwd'
        },
        {
          id: 2,
          name: '收货地址',
          icon: require('@/assets/img/profile/icon_address.png'),
          route: 'address'
        },
        {
          id: 3,
          name: '团队奖提现记录',
          icon: require('@/assets/img/profile/icon_reward.png'),
          route: 'teamReward'
        },
        {
          id: 4,
          name: 'APP下载',
          icon: require('@/assets/img/profile/icon_download.png'),
          route: 'downloadApp'
        },
        // {
        //   id: 5,
        //   name: '关于中国平安集团',
        //   icon: require('@/assets/img/profile/icon_about.png'),
        //   route: 'about'
        // },
        {
          id: 6,
          name: '退出登录',
          icon: require('@/assets/img/profile/icon_logout.png'),
          route: 'logout'
        }
      ],
      functionButtons: [
        {
          id: 0,
          name: '我的团队',
          icon: require('@/assets/img/profile/icon_team.png'),
          route: 'team'
        },{
          id: 1,
          name: '单据凭证',
          icon: require('@/assets/img/profile/icon_receipt.png'),
          route: 'fundDetail'
        },
        {
          id: 2,
          name: '实名认证',
          icon: require('@/assets/img/profile/icon_auth.png'),
          route: 'realNameAuth'
        },
        {
          id: 3,
          name: '收货地址',
          icon: require('@/assets/img/profile/icon_address.png'),
          route: 'address'
        },
        {
          id: 4,
          name: '提现记录',
          icon: require('@/assets/img/profile/icon_withdraw_record.png'),
          route: 'withdrawRecord'
        },
        {
          id: 5,
          name: '资金明细',
          icon: require('@/assets/img/profile/icon_fund.png'),
          route: 'fundDetail'
        },
        {
          id: 6,
          name: '绑定银行卡',
          icon: require('@/assets/img/profile/icon_bank.png'),
          route: 'bindBankCard'
        },
        {
          id: 7,
          name: '官方群聊',
          icon: require('@/assets/img/profile/icon_chat.png'),
          route: 'contactUs'
        },
        {
          id: 8,
          name: '修改密码',
          icon: require('@/assets/img/profile/icon_password.png'),
          route: 'modifyPwd'
        }
      ]
    };
  },
  mounted() {
    this.getUserInfo();
    this.getSystemInfo();
    this.getIdentifyInfo();
    this.getUserWallet();
  },
  filters: {
    toIntegerOrZero(value) {
      if (value !== undefined) {
        return Math.floor(value);
      } else {
        return 0;
      }
    },
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
    formatDecimal(value) {
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      return parseFloat(value).toFixed(0);
    },
    formatAmount(value) {
      if (!value || isNaN(value)) {
        return '0';
      }
      const num = Number(value);
      if (Number.isInteger(num)) {
        return num.toString();
      }
      return num.toFixed(2);
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo", "getIdentifyInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    jumpToWithdraw(data) {
      // if(type==WithdrawalType.GRSDS){
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '15日开启提现，敬请期待',
      //   })
      //       .then(() => {
      //       })
      //       .catch(() => {});
      //   return
      // }
      // if (type == WithdrawalType.GZ && amount <= 100) {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '满100可提',
      //   })
      //       .then(() => {
      //       })
      //       .catch(() => {
      //       });
      //   return
      // }
      // if ((type == WithdrawalType.REFERRAL||type == WithdrawalType.CASH)&&this.userInfo.rjmoney<=0) {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '先购买生活保障！',
      //   }).then(()=>{
      //     this.$router.push({path: '/livingsecurity'});
      //   })
      //   return
      // }
      // if (isNullOrEmpty(this.userInfo.auth)) {
      //   this.$dialog.confirm({
      //     title: '提示',
      //     message: '参与抽奖需要实名认证，是否前往实名认证？',
      //   })
      //       .then(() => {
      //         this.$router.push({name: 'realNameAuth'});
      //       })
      //       .catch(() => {
      //       });
      //   return
      // }
      this.$router.push({
        name: "withdrawal",
        query: data,
      });
    },
    jumpToWithdrawDetail(data) {
      this.$router.push({
        name: "fundDetail",
        query: {
          type:data.type,
        },
      });
    },
    jumpToPage(name) {
      if (name == 'downloadApp') {
        this.$router.push({name: 'inviteUser', query: {code: this.userInfo.code}});
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      if (name == 'teamReward') {
        this.$router.push({name: 'withdrawRecord', query: {type: 'income_wallet'}});
        return
      }
      this.$router.push({name});
    },
    getUserWallet() {
      axios.get(api.userWallet)
        .then((res) => {
          if (res.code === 200 && res.data) {
            const walletInfo = {};
            // 将coinList数组转换为以name_en为key的对象
            res.data.coinList.forEach(coin => {
              walletInfo[coin.name_en] = coin;
            });
            this.walletInfo = walletInfo;
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.pageLoading = false
        })
    },
    onLoginOut() {
      this.$dialog.confirm({
        title: '提示',
        message: '确定要退出登录吗？',
      })
          .then(() => {
            this.$store.commit("tabs/ACCOUNT_LOGOUT_FAILURE", true);
          })
          .catch(() => {
          });
    },

    loadMore() {
      axios
          .get(api.receiveList)
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.recommendRecords = res.data.map(item => {
                return item.recom_num
              })
            }
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    onCopyText() {
      console.log('复制邀请链接', this.userInfo);
      const text = `${this.$config.WEB_URL}/register?code=${this.userInfo.code}`;
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！')
    },
    onRefresh() {
      this.refreshing = true;
      this.pageLoading = true;
      Promise.all([
        this.getUserInfo(),
        this.getSystemInfo(),
        this.getUserWallet()
      ]).finally(() => {
        this.refreshing = false;
        this.pageLoading = false;
      });
    },
    handleSignIn() {
      axios.post(api.signIn)
          .then((res) => {
            this.$toast('签到成功');
            this.getUserInfo();
            this.getUserWallet();
          })
          .catch((err) => {
            if (err.code === 430) {
              this.$dialog.alert({
                message: err.msg || '请先完成实名认证',
                confirmButtonText: '去认证',
                confirmButtonColor: '#F34947',
              }).then(() => {
                this.$router.push({name: 'realNameAuth'});
              });
            } else {
              err.msg && this.$toast(err.msg);
            }
          });
    },
  },
};
</script>
